html, body {
  font-size: 16px;
  line-height: 1.5;
  background: #f8f8f8;
}
p {
  font-weight: normal !important;
}

#root {
  background: #f8f8f8;
}

.dashboard-btn:hover {
  color: inherit;
}

.riesgo-Alto {
  color: #ff000a;
  color: rgba(255, 0, 10, 255);
}

.riesgo-Medio-Alto {
  color: #ff5800;
  color: rgba(255, 88, 0, 255);
}

.riesgo-Medio {
  color: #ffdd00;
  color: rgba(255, 221, 0, 255);
}

.riesgo-Medio-Bajo {
  color: #7bca19;
  color: rgba(123, 202, 25, 255);
}

.riesgo-Bajo {
  color: #01983b;
  color: rgba(1, 152, 59, 255);
}

@media print {
  #print_to_pdf {
    background: #f3f3f3f3;
  }
  .no-print {
    display: none;
  }
}