/* Dashboard */
.Dashboard nav.navbar {
    border-top: 4px solid #276cda;
    margin-bottom: 1rem;
  }
  .Dashboard .navbar-item.brand-text {
    font-weight: 300;
  }
  .Dashboard .navbar-item, .navbar-link {
    font-size: 14px;
    font-weight: 700;
  }
  .Dashboard .columns {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
  .Dashboard .menu-label {
    color: #8F99A3;
    letter-spacing: 1.3;
    font-weight: 700;
  }
  .Dashboard .menu-list a {
    color: #0F1D38;
    font-size: 14px;
    font-weight: 700;
  }
  .Dashboard .menu-list a:hover {
    background-color: transparent;
    color: #276cda;
  }
  .Dashboard .menu-list a.is-active {
    background-color: transparent;
    color: #276cda;
    font-weight: 700;
  }
  .Dashboard .card {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
    margin-bottom: 2rem;
  }
  .Dashboard .card-header-title {
    color: #8F99A3;
    font-weight: 400;
  }
  .Dashboard .info-tiles {
    margin: 1rem 0;
  }
  .Dashboard .info-tiles .subtitle {
    font-weight: 300;
    color: #8F99A3;
  }
  .Dashboard .hero.welcome.is-info {
    background: #36D1DC;
    background: -webkit-linear-gradient(to right, #5B86E5, #36D1DC);
    background: linear-gradient(to right, #5B86E5, #36D1DC);
  }
  .Dashboard .hero.welcome .title, .hero.welcome .subtitle {
    color: hsl(192, 17%, 99%);
  }
  .Dashboard .card .content {
    font-size: 14px;
  }
  .Dashboard .card-footer-item {
    font-size: 14px;
    font-weight: 700;
    color: #8F99A3;
  }
  .Dashboard .card-table .table {
    margin-bottom: 0;
  }
  .Dashboard .events-card .card-table {
    max-height: 250px;
    overflow-y: scroll;
  }
  
  .Dashboard code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  @media screen and (max-width: 790px) {
    .Dashboard .breadcrumb {
        padding: 0 1rem !important;
    }
  }

  .Dashboard .notification a:not(.button):not(.dropdown-item) {
    text-decoration: none !important;
  }
  
  
.Dashboard .information {
  padding: 0 1.5rem 2rem 1.5rem;
}

  /* Loader */
  .Dashboard .bcLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }